.galleryContainer {
  width: 100%;
  position: relative;
}
.containImage {
  object-fit: cover;
  margin: 0;

  height: 50vh;
}
.coverImage {
  object-fit: cover;
}
.gallery {
  display: grid;
  width: 100%;
  height: 60vh;
  grid-template-columns: 2fr 0.59fr 0.5fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'left mLeftTop mRightTop'
    'left mLeftBottom mRightBottom';

  grid-gap: 10px;
  column-gap: 10px;
  row-gap: 10px;
}

.fullImage,
.mLeftTop,
.mRightTop,
.mLeftBottom,
.mRightBottom {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.5s, box-shadow 0.3s;
  cursor: pointer;
}
.fullImage {
  object-fit: cover;
  object-position: top;
  grid-area: left;
  height: 60vh;
}
.mLeftTop {
  grid-area: mLeftTop;
  height: 30vh;
}
.mRightTop {
  grid-area: mRightTop;
  height: 30vh;
}
.mLeftBottom {
  grid-area: mLeftBottom;
  height: 29vh;
}
.mRightBottom {
  grid-area: mRightBottom;
  height: 29vh;
}
.bottom {
  width: 100%;
  height: 29vh;
  object-fit: cover;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 4;
}

.fullImage:hover,
.mLeftTop:hover,
.mRightTop:hover,
.mLeftBottom:hover,
.mRightBottom:hover {
  transform: translateY(-2px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.btn {
  position: absolute;
  width: auto;

  bottom: 0;
  right: 0;
  margin: 1em;

  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 0.5em;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  border: none;
  font-size: 13px;
}
.btnImg {
  width: 20px;
}
.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(1px);
  box-shadow: none;
  border: 2px solid var(--marketplaceColorSecond);
}
/*gallery styles*/
.modalGallery {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(200px, auto));
  grid-template-areas:
    'top top top top'
    'slrow slrow srrow srrow'
    'tlrow tlrow turow turow'
    'tlrow tlrow tdrow tdrow'
    'furow furow frrow frrow'
    'fdrow fdrow frrow frrow'
    'filrow filrow firrow firrow'
    'silrow silrow sirrow sirrow'
    'bottom bottom bottom bottom';
  grid-gap: 10px;
}

.modalImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  object-position: left;

  &:nth-child(1) {
    grid-area: top;
  }

  &:nth-child(2) {
    grid-area: slrow;
  }

  &:nth-child(3) {
    grid-area: srrow;
  }

  &:nth-child(4) {
    grid-area: tlrow;
  }

  &:nth-child(5) {
    grid-area: turow;
  }

  &:nth-child(6) {
    grid-area: tdrow;
  }

  &:nth-child(7) {
    grid-area: frrow;
  }
  &:nth-child(8) {
    grid-area: furow;
  }
  &:nth-child(9) {
    grid-area: fdrow;
  }
  &:nth-child(10) {
    grid-area: filrow;
  }
  &:nth-child(11) {
    grid-area: firrow;
  }
  &:nth-child(12) {
    grid-area: silrow;
  }
  &:nth-child(13) {
    grid-area: sirrow;
  }
  &:nth-child(14) {
    grid-area: bottom;
  }
  &:nth-last-child(1) {
    grid-area: span 0 / span 4;
  }

  /* Add more nth-child selectors for additional children if needed */
}
