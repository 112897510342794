.langContainer{
    display: flex;
    align-items: center;
    margin:1em 0;
}

.langImg{
    width: 100px;
    margin-right: 2em;
}
.languageList{
    list-style-type: disc;
    margin-left:1em
}