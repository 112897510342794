/* faqDisplay.module.css */

.faqContainer {
  width: 100%;
  max-width: 800px;
/*   margin: 0 auto; */
  padding-bottom: 20px;
  padding-right: 20px;
  font-family: Arial, sans-serif;
  background-color: transparent;

}

.question {
  cursor: pointer;
  padding: 15px;
  margin-bottom: 10px 0;
  border-bottom: 2px solid #ddd;
  background-color: transparent;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
}

.question:hover {
  background-color: #f1f1f1;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
}

.answer {
  top: 0;
  padding: 0.5em 1em 1em 1em;
  background-color: transparent;
/*   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  animation: fadeIn 0.3s ease-in-out;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
}

.arrow {
  font-size: 18px;
  transition: transform 0.3s ease;
}

.arrow.expanded {
  transform: rotate(90deg);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.questionContainer{
  width: 100%;
}

.faqwrapper{
  width: 100%;
  display: flex;
  justify-content:flex-start;
  align-items: center;
}
