@import '../../../styles/customMediaQueries.css';


.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  max-width: 700px;

  @media screen and (max-width: 1000px) {
    max-width: 600px;
  }
  @media screen and (max-width: 600px) {
    max-width: 500px;
  }
  @media screen and (max-width: 500px) {
    max-width: 350px;
  }
}

.hearAboutField {
  margin-top: 20px;
}
