
@import '../../../../styles/customMediaQueries.css';

.listingInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 0.5;

  margin: 0 1em;
  @media (--viewportSmall) {
    flex-direction: row;
    margin: 0;
    align-items: baseline;
  }
}

.titleNcharacteristics {
  margin-bottom: 20px;
  font-family: Noto Sans JP;
  color: var(--successColor);
}
.charTxt {
  font-size: 17px;
  color: var(--successColor);
  @media (--viewportMedium) {
    font-size: 22px;
  }
  @media (--viewportMaxSmallDevice) {
    font-weight: normal;
    font-size: 12px;
  }
}

.characteristicsWrapper {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  @media (--viewportMedium) {
    position: relative;
    top: 7px;
    gap: 15px;
  }
  @media (--viewportSmall) {
    justify-content: flex-start;
  }
}
.charWrapper {
  max-width: 40%;
  @media screen and (max-width: 550px) {
    width: 75%;
  }
}
.characteristics {
  display: flex;
  align-items: center;
  gap: 10px;
}

.accomodationFeatures {
  font-size: 12px;
  letter-spacing: 0.4em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  font-family: 'Inter';
  color: var(--matterColor);
  margin-top: 5px;
  @media (--viewportMedium) {
    font-size: 14px;
  }
  @media (--viewportMaxSmallDevice) {
    font-size: 7px;
  }
}

.subSections {
  padding-left: 10px;
  padding-right: 10px;
  @media (--viewportMaxSmallDevice) {
    font-size: 1.6em;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.person {
  @media (--viewportMaxSmallDevice) {
    font-size: 1.6em;
  }
}

.hostName {
  cursor: pointer;
  text-decoration: underline;
}

.viewProfileButton{
  width: 180px;
  padding: 0;
  margin-top: 1em;
  color: white;
  &:hover{
    background-color: #d35400;
  }
}

.modalContentWrapper{
  display: flex;
  flex-direction: column;
  height: fit-content;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}


/* Left section of the modal for avatar and reviews */
.modalLeftSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-right: 1em;
  padding-bottom: 1em;
  max-width: fit-content;

  @media (--viewportMedium) {
    align-items: center;
    padding-bottom: 0em;

  }
}

/* Right section of the modal for text and profile button */
.modalRightSection {
  flex: 2;
  display: flex;
  flex-direction: column;
}

/* Modal avatar styling */
.avatarMedium {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}

/* Modal host name styling */
.modalRightSection h2 {
  margin: 0;
  font-size: 1.5rem;
}

/* Modal description text */
.modalRightSection p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

