
@import '../../../../styles/customMediaQueries.css';
/* ModalDrawer.css */

.offeringsContainer {
  margin: 0 1em;
  @media (--viewportMedium) {
    position: relative;
    padding-bottom: 1em; /* Adjust this value as needed */
    margin: 0;
  }
}

.offeringsContainer > h2 {
  font-family: Noto Sans JP;
  color: var(--successColor);
}
.offeringsContainer::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to top, rgba(128, 128, 128, 0.5) 0%, rgba(128, 128, 128, 0.2) 100%);
}
.specialSelectionListContainer {
  column-count: 1;

  margin: 1em 0;
  background-color: antiquewhite;
}

.specialSelection {
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin: 1em 0;
}
.specialSelectionCrossed {
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin: 1em 0;
  text-decoration: line-through;
}

.specialSelectionImg {
  width: 50px;
  margin-right: 1em;
}
/* ModalDrawer.css */
.modalDrawer {
  position: fixed;
  top: 6%;
  left: 0;
  width: 100%;
  height: 95%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  opacity: 1;
  backdrop-filter: blur(5px);
  @media screen and (max-width: 1300px) {
    top: 9%;
    height: 92%;
  }
}
.modalOverlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 1;
}

.modalContent {
  background-color: white;
  padding: 20px;
  width: 90%; /* Adjust the width as desired */
  /* Set a maximum width for the modal */
  height: 90%; /* Remove the fixed height */
  border-radius: 8px;
  /* animation: slideIn 0.5s ease-in-out; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow: auto;

  @media (--viewportMedium) {
    width: 70%;
    height: 90%;
  }
  @media (--viewportLarge) {
    width: 50%;
    height: 90%;
  }
}

.openBtn {
  margin: 2em 1em 1em 0;
  background-color: white;
  height: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1em;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  border: 1px solid #eb7242;
  color: #eb7242;
  cursor: pointer;
}

.openBtn:hover {
  transform: translateY(-2px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.openBtn:active {
  transform: translateY(1px);
  box-shadow: none;
  border: 2px solid var(--marketplaceColorSecond);
}

.closeBtnContainer {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: transparent; /* Adjust as needed */
}

.closeBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border: none;
  align-items: center;
}

.closeBtnImg {
  width: 40px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}
.closeBtnImg:hover {
  transform: translateY(-2px);
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
