.featuresListContainer{
    column-count: 1;
    margin:1em 0;
 }
 .features{
     display: flex;
     align-items: flex-end;
     flex-direction: row-reverse;
     justify-content: flex-end;
     margin:1em 0;
   
 }
 
 .featuresImg{
     width: 50px;
     margin-right: 1em;
     
 }
 .title{
    color: #353535;
 }