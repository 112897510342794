
@import '../../../../styles/customMediaQueries.css';

.aboutAccomodationWrapper {
  margin: 1em;
  @media (--viewportMedium) {
    margin: 1em 0 0 0;
  }
}

.descriptionWrapper {
  margin-top: 0px;
  display: flex;
  gap: 0px;
  flex-direction: column;
  margin-bottom: 30px;
}
.descriptionWrapper > h2 {
  font-family: Noto Sans JP;
  color: var(--successColor);
}

.roomtypeWrapper {
  margin-top: 40px;
}

.supportMoreBtn {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  margin-top: -10px;
  cursor: pointer;
  width: fit-content;
}

.supportText {
  margin-bottom: 1em;
}

.arrowDownIcon {
  width: 32px;
  height: 32px;
  border: 3px solid var(--marketplaceColorSecond);
  border-radius: 50%;
  text-align: center;
}
.stayBtnWrapper {
  margin-top: 40px;
}
.stayBtnWrapper > h2 {
  font-family: Noto Sans JP;
  color: var(--successColor);
}

.btnTxt {
  margin-left: 5px;
  color: var(--marketplaceColorSecond);
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media screen and (min-width: 1150px) {
    gap: 30px;
  }
}

.listing {
  width: 300px;
  margin-top: 30px;

  @media (--viewportMedium) {
    width: 320px;
    margin-bottom: 36px;
    margin-top: 10px;

    &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 0px;
    }
  }
  @media (--viewportXLarge) {
    &:nth-of-type(odd) {
      margin: 20px 10px 0 10px;
    }
    &:nth-of-type(even) {
      margin: 20px 10px 0 10px;
    }
  }

  @media (--viewportMaxSmallDevice) {
    width: 320px;
    margin-bottom: 30px;

    &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 0px;
    }

    margin-top: 0;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-top: 20px;
    }
  }
}

.btn {
  padding-bottom: 3em;
  margin: 1em 1em 1em 0;
  background-color: transparent;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  border-radius: 10px;
  border: none;
  color: var(--marketplaceColorSecond);
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-2px);
}

.btnText {
  text-decoration: underline;
}

.Gt {
  text-decoration: none;
  margin-left: 0.5em;
}

.shortenedText {
  position: relative;
  margin-right: 2em;
  padding-right: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.showFullText {
  margin-right: 2em;
  margin-bottom: 1em;
}
.showFullText .shortenedText::after {
  display: none;
}

.verticalLine {
  width: 75%;
  @media (--viewportMaxSmallDevice) {
    width: 100%;
  }
}

.highlightCard {
  display: flex;
  align-items: center;
  justify-content:flex-start;
  margin-top: 1em;
}

.highlightImg {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  margin-top: 0px;
  display: flex;
}

.highlightCardcontent {
  display: flex;
  justify-content:flex-start;
  align-items:flex-start;
  flex-direction: column;
  max-height: 90px;
}

.highlightText {
  text-align:flex-start;
  display: flex;
  margin-top: 0.5em;
}

.highlightTitle {
  color: #4a4a4a;
  line-height: 0.90;
  font-size: 1.2em;
  font-weight: 800;
}

.includedWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3em;
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
}

.includedRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1em;
  gap: 1em;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
}

.includedElement {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
  gap: 0.25em;
  @media (--viewportMedium) {
    width: 50%;
  }
}
.includedLabel {
  display: flex;
}

.includedIcon {
  width: 40px;
  height: 40px;
  justify-self:flex-end;
}
