.slider {
  position: relative;

  height: 100%;
  overflow: hidden;
}

.slide {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slideImg {
  width: 100%;
  height: auto;
}

.hidden {
  display: none;
}


.imgInfo {
  position: absolute;
  width: auto;
  bottom: 0;
  right: 0;
  margin: 1em;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1em;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  border: none;
  font-size: 13px;
}

.imgInfo p {
  margin: 0;
}

/*media queries for phone with bigger screens z.B Pixel 7*/

@media (min-width: 1439px) {
  .slider {
    position: relative;
    width: 95vw; /* Use viewport width instead of 100% */
    height: 100%;
    overflow: hidden;
    margin: 0;
  }
}
