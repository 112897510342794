
@import '../../../../styles/customMediaQueries.css';
.activities {
  position: relative;
  overflow: hidden;
  margin: 0 1em;

  @media (--viewportMedium) {
    margin: 0;
    padding-bottom: 1em;
  }
}
.activities > h2 {
  font-family: Noto Sans JP;
  color: var(--successColor);
}
.activities::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to top, rgba(128, 128, 128, 0.5) 0%, rgba(128, 128, 128, 0.2) 100%);
}
.shortenedText {
  position: relative;
  margin-right: 2em;
  overflow: hidden;
  display: -webkit-box;
  /* -webkit-line-clamp: 5; */
  -webkit-box-orient: vertical;
}

.showFullText .shortenedText::after {
  display: none;
}
.btnImg {
  width: 25px;

  margin-right: 1em;
}

.btn {
  padding-bottom: 3em;
  margin: 1em 1em 1em 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  border-radius: 10px;
  border: none;
  color: var(--marketplaceColorSecond);
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-2px);
}

.btn:active {
  transform: translateY(1px);
  box-shadow: none;
}
.btnText {
  text-decoration: underline;
}
.Gt {
  text-decoration: none;
  margin-left: 0.5em;
}
