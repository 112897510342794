@import '../../../../../styles/customMediaQueries.css';


.container {
  margin-top: 0px;
  height: 740px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
  padding: 0;
  @media screen and (max-width: 1000px) {
    height: 780px;
  }
  @media screen and (max-width: 560px) {
    height: 700px;
  }
}
.mainWrapper {
  width: screen !important;
  margin-bottom: 0px !important;
  height: 700px;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin: 0 auto;
    max-width: 1300px;
  }
  @media (--viewportXLarge) {
    max-width: 1400px;
  }
}

.sectionTitle {
  color: #1c7881;
  margin-top: 40px;
  font-size: 21px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  @media screen and (max-width: 700px) {
    margin-left: 2em;
  }
}
.sectionTitle span {
  width: 90%;
}
.img {
  max-width: 93%;
  height: 320px;
  object-fit: cover;
  margin: 13px;
  border-radius: 20px;
  margin-top: -56px;
}

.spanDescription {
  display: flex;
  flex-direction: column;
}

.listDescriptionWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

.descriptionTxt {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 15px;
  height: 60px;
  color: #1c7881;
}

.price {
  font-weight: bold;
  font-size: 14px;
  height: 60px;
  color: #1c7881;
}

.ab {
  font-weight: bold;
  font-size: 15px;
  height: 60px;
  color: black;
}

.pinlogo {
  padding: 0 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -80px;
}

.location {
  font-weight: bold;
  font-size: 12px;
  height: 60px;
  color: black;
  margin-left: 5px;
  margin-top: 30px;
}

/**Suggested Listing cards css**/
.allCardsWrapper {
  display: flex;
  flex-direction: row;
  margin-left: 6%;

  @media (min-width: 1920px) {
    margin-left: 7%;
  }
  @media (max-width: 550px) {
    margin-left: -2.5em;
  }
  @media (max-width: 320px) {
    margin-left: -7%;
  }
}

.outerWrapper {
  width: 334px;
  height: 504px;
}

.innerWrapper {
  background-color: white;
  margin: 10px;
  border-radius: 15px;
  height: 480px;
  position: relative;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding-top: 11px;

  @media (max-width: 550px) {
    height: 430px;
  }
  @media screen and (max-width: 1400px) {
    height: 490px;
  }
}
.imageWrapper {
  position: relative;
  max-width: 93%;
  height: 320px;
  margin: 11px;
  border-radius: 20px;
  overflow: hidden;
}

.imageWrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.7)
  );
  z-index: 2;
  pointer-events: none; /* Ensures the overlay doesn't interfere with clicks */
}

.cardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  z-index: 1; /* Ensure the image is below the overlay */
}

.cardImg:hover {
  cursor: pointer;
  z-index: 100;
}

.projectTitleWrapper {
  margin-top: 0px;
  padding-left: 20px;
  max-width: 96%;
}

.projectName {
  font-weight: bold;
  font-size: 13px;
  color: black;
  line-height: 0.6;
  @media screen and (max-width: 1400px) {
    line-height: 0.6;
  }
}

.projectLocationWrapper {
  position: absolute;
  bottom: -10px;
  left: 20px;
  display: flex;
  align-items: center;
}

.slide {
  position: absolute;
  width: 1%;
  height: 1%;
  object-fit: cover;
  border-radius: 15px;
  transition: opacity 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84), transform 600ms ease;
  @media (min-width: 768px) {
    width: 100%;
  }
}

.slide-0 {
  transform: translateX(-30%);
  opacity: 0.3;
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    transform: translateX(-40%);
  }
  @media (max-width: 768px) {
    transform: translateX(-65%);
  }
}

.slide-1 {
  transform: translateX(0%);
  opacity: 1;
  @media (max-width: 768px) {
    transform: translateX(0%);
  }
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    transform: translateX(0%);
  }
}

.slide-2 {
  transform: translateX(30%);
  @media (max-width: 768px) {
    transform: translateX(65%);
  }
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    transform: translateX(40%);
  }
}

.slide-3 {
  transform: translateX(60%);
  @media (max-width: 768px) {
    transform: translateX(130%);
  }
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    opacity: 0.3;
    transform: translateX(80%);
  }
}

.slide-4 {
  transform: translateX(90%);
  opacity: 0.3;
  @media (max-width: 768px) {
    transform: translateX(195%);
  }
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    transform: translateX(120%);
  }
}

.slide-5 {
  transform: translateX(120%);
  @media (max-width: 768px) {
    transform: translateX(260%);
  }
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    transform: translateX(160%);
  }
  opacity: 0.3;
}

.hidden {
  display: none;
}
.slider-buttons {
  position: absolute;
  display: flex;
  top: 80%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 7;
  width: 100%;
  justify-content: space-between;
}

.slider-button-left {
  position: absolute;
  display: flex;
  top: 65%;
  left: 4%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 99;
  width: 6%;
  justify-content: space-between;
}

.slider-button {
  padding: 0.5em 0.8em;
  background: white;
  border: 1px solid #eb7242;
  border-radius: 50%;
  margin: 1em;
  width: 45px;
  color: #eb7242;
}

.slider-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.slider-button:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.backToSearchBtn {
  width: fit-content;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
  border-radius: 10px;
  background-color: #eb7242;
  z-index: 1;
}

.backToSearchBtn:hover {
  background-color: #ef9c7c;
}

/*Mobile Slider css*/
.mobileSlide {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  transition: opacity 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84), transform 600ms ease;
}
.mobileSlide-0 {
  transform: translateX(-115%);
  opacity: 0.3;
}
.mobileSlide-1 {
  transform: translateX(0%);
  opacity: 1;
}
.mobileSlide-2 {
  transform: translateX(125%);
}
.mobileSlide-3 {
  transform: translateX(230%);
}
.priceText {
  font-size: 14px;
}

.spinnerContainer {
  max-width: 93%;
  height: 320px;
  object-fit: cover;
  padding-top: 110px;
  padding-left: 25px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinnerDot {
  width: 10px;
  height: 10px;
  background-color: #1c7881;
  border-radius: 50%;
  margin: 0 5px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.discoverContainer {
  position: relative;
  z-index: 1;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 375px) {
    margin-bottom: 7em;
    margin-left: -3em;
  }
  @media screen and (max-height: 915px) {
    margin-bottom: 7em;
    margin-left: -2em;
  }
  @media screen and (width: 320px) and (height: 480px) {
    margin-bottom: 7em;
    margin-left: -2em;
  }
  @media screen and (width: 390px) and (height: 844px) {
    margin-bottom: 7em;
    margin-left: -2em;
  }
  @media screen and (width: 414px) and (height: 896px) {
    margin-bottom: 7em;
    margin-left: 0em;
  }
  @media screen and (min-width: 425px) {
    margin-bottom: 7em;
    margin-left: -0.5em;
  }
  @media screen and (min-width: 1023px) {
    margin-bottom: 0;
  }
}

.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 254, 254, 0.3);
  border-radius: 50%;
  border-top-color: #045e66;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loadingContainer {
  margin-top: 35px;
  width: 60px;
  height: 60px;
  color: #000000;
  text-align: center;
}

.address {
  position: absolute;
  color: white;
  z-index: 3;
  right: 19px;
  top: 15px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.daysLabel {
  position: absolute;
  color: white;
  z-index: 3;
  left: 19px;
  bottom: 35px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content:flex-start;
  flex-direction: column;
  max-width: fit-content;
  overflow: hidden;
  gap: 8px;
}

.discoverBtn {
  background-color: transparent;
  border: 1px solid #eb7242;
  border-radius: 4px;
  color: #eb7242;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 9px;
  padding-top: 9px;
  width: fit-content;
}

.lastSectionWrapper {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self:flex-end;
  position: absolute;
  bottom: 0px;
  width: 85%;
}
.priceWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80px;
  padding-top: 10px;
  height: 70px;
}

.btnWrapper {
  background-color: transparent;
  height: fit-content;
  width: 55vh;
  justify-items: auto;
  display: flex;
  justify-content: flex-start;
  /* margin-left: -155px; */
  margin-top: 20px !important;
  @media screen and (max-width: 575px) {
    margin-top: 95px !important;
  }
}

.parent {
  padding-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content:flex-start;
  flex-direction: column;

  @media (--viewportMedium) {
    margin: 0 auto;
    max-width: 1300px;
  }
  @media (--viewportXLarge) {
    max-width: 1400px;
  }
}

.parentWrapper{
  width: 95vw;
  @media (--viewportMedium) {
    margin: 0 auto;
    max-width: 1300px;
  }
  @media (--viewportXLarge) {
    max-width: 1400px;
  }
}
