@import '../../styles/customMediaQueries.css';

.signupForm {
  margin-top: 24px;
  /* We don't want the form to take the whole space so that on mobile view
    the social login buttons will be after the sign up button
    and not in the bottom of the page.
    We also need to add flex-basis value so that Safari will show the
    whole form correctly.  */
  flex-grow: 0;
  flex-basis: 340px;

  @media (--viewportMedium) {
    flex-basis: 410px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.loginForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 260px;

  @media (--viewportMedium) {
    flex-basis: 330px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.wrapper {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 1000px) {
    margin-top: 50px;
  }
}

.tabsSwitcher {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Noto Sans JP;
}

.tabBase {
  margin: 0 10px;
  font-size: 30px;
  padding-bottom: 5px;
}

.tab {
  composes: tabBase;
  cursor: pointer;
  &:hover {
    color: var(--marketplaceColor);
    border-bottom: 5px solid var(--marketplaceColor);
    padding-bottom: 0;
  }
}

.tabSelected {
  composes: tabBase;
  color: var(--marketplaceColor);
  border-bottom: 5px solid var(--marketplaceColor);
  padding-bottom: 0;
}

.formsWrapper {
  margin-top: 20px;
}

.signupWrapper{
  margin-top: 20px;
  @media screen and (min-width: 770px) {
      overflow-y: auto;
      overflow-x: none ;
      overflow-x: hidden;
      max-height: 400px;
  }
}
.error {
  composes: marketplaceModalPasswordMargins from global;
  color: red;
}
