
@import '../../../../styles/customMediaQueries.css';
.section {
  position: relative;
  overflow: hidden;
  margin: 0 1em;

  @media (--viewportMedium) {
    margin: 0;
    padding-bottom: 2em;
  }
}
.section > h2 {
  font-family: Noto Sans JP;
  color: var(--successColor);
}
.section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to top, rgba(128, 128, 128, 0.5) 0%, rgba(128, 128, 128, 0.2) 100%);
}

.openBtn {
  margin: 2em 1em 1em 0;
  background-color: white;
  height: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1em;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  border: 1px solid #eb7242;
  color: #eb7242;
  cursor: pointer;
  width: fit-content;
}

.openBtn:hover {
  transform: translateY(-2px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.openBtn:active {
  transform: translateY(1px);
  box-shadow: none;
  border: 2px solid var(--marketplaceColorSecond);
}
