.amenitiesListContainer{
   column-count: 1;
   margin:1em 0;
}
.amenities{
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin:1em 0;
  
}

.amenitiesImg{
    width: 50px;
    margin-right: 1em;
    
}
.title{
    color: #353535;
 }