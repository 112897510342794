@import '../../../../styles/customMediaQueries.css';

.sectionHeading {
  margin-bottom: -30px;
}

.heading {
  margin-bottom: 33px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.title {
  /* Font */
  /* @apply --marketplaceModalTitleCustomStyles; */
  color: var(--matterColor);
  font-size: 22px;
  font-family: Noto Sans JP;
  font-weight: 600;
  line-height: 1.2;

  /* Layout */
  width: 100%;
  margin: 50px 0 0 0px;
  text-align: left;

  @media (--viewportMedium) {
    margin-bottom: 2px;
    font-size: 1.5em;
  }
}
