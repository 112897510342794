.noReviewsBtnTop {
  margin-bottom: 0px;
  margin-top: 0px;
  background-color: white;
  height: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  color: white;
  background: #eb7242;
  cursor: pointer;
  width: 100%;
  z-index: 99999;
}

.bookingDetailsNavbar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 180px;
}
