@import '../../../styles/customMediaQueries.css';


.generalInfoConatiner{
  margin: 0 1em 1em 1em;
  @media (--viewportMedium) {
    margin: 0 auto;
  }
}

.list {
  list-style-type: disc;
  margin: 0.5em 0;
  padding-left: 20px;
}

.listItem {
  margin: 0.2em 0;
}

.list > .listItem > .list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.list > .listItem > .list > .listItem::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  border: 2px solid #4a4a4a;
  border-radius: 50%;
  margin-right: 8px;
  vertical-align: middle;
}
