@import '../../../../styles/customMediaQueries.css';


.container {
  margin-top: 30px;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    height: 540px;
    margin-bottom: 6em;
  }
  @media screen and (max-width: 560px) {
    height: 530px;
  }
}
.mainWrapper {
  
  margin-left: 2em;


  @media (--viewportMedium) {
    margin: 0 auto;
    max-width: 1300px;
  }
  @media (--viewportXLarge) {
    max-width: 1400px;
  }
}
.sectionTitle {
  color: #1c7881;
  margin-top: 40px;
  /*   margin-left: 1.3em; */
  font-size: 21px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  @media screen and (min-width: 768px) {
    font-size: 26px;
  }
}
.sectionTitle span {
  width: 90%;
}
.img {
  max-width: 93%;
  height: 320px;
  object-fit: cover;
  margin: 13px;
  border-radius: 20px;
  margin-top: -56px;
}

.spanDescription {
  display: flex;
  flex-direction: column;
}

.listDescriptionWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

.descriptionTxt {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 15px;
  height: 60px;
  color: #1c7881;
}

.price {
  font-weight: bold;
  font-size: 14px;
  height: 60px;
  color: #1c7881;
}

.ab {
  font-weight: bold;
  font-size: 15px;
  height: 60px;
  color: black;
}

.pinlogo {
  padding: 0 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -80px;
}

.location {
  font-weight: bold;
  font-size: 12px;
  height: 60px;
  color: black;
  margin-left: 5px;
  margin-top: 30px;
}

/**Suggested Listing cards css**/
.allCardsWrapper {
  display: flex;
  flex-direction: row;
  margin-left: 6%;

  @media (min-width: 1920px) {
    margin-left: 7%;
  }
  @media (max-width: 550px) {
    margin-left: -2.5em;
  }
  @media (max-width: 320px) {
    margin-left: -7%;
  }
}

.outerWrapper {
  width: 334px;
  height: 504px;
}

.innerWrapper {
  background-color: var(--boxShadow);
  margin: 10px;
  border-radius: 15px;
  height: 480px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  @media (max-width: 550px) {
    height: 430px;
  }
  @media screen and (max-width: 1400px) {
    height: 490px;
  }
  &:hover{
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
    scale: 1.01;
  }
  &:active{
    scale: 0.99;
  }
}

.imageWrapper {
  cursor: pointer;
}
.imageWrapper:hover {
  cursor: pointer;
  z-index: 100;
}

.cardImg {
  max-width: 93%;
  height: 320px;
  object-fit: cover;
  margin: 11px;
  border-radius: 13px;
  cursor: pointer;
  @media (max-width: 550px) {
    height: 300px;
  }
}
.cardImg:hover {
  cursor: pointer;
  z-index: 100;
}

.projectTitleWrapper {
  margin-top: 0px;
  padding-left: 20px;
  /*   background-color: red; */
  /* max-height: 100px; */
}

.projectName {
  font-weight: bold;
  font-size: 15px;
  color: #1c7881;
  @media screen and (max-width: 1400px) {
    line-height: 0.8;
  }
}

.projectLocationWrapper {
  position: absolute;
  bottom: -10px;
  left: 20px;
  display: flex;
  align-items: center;
}

/*slide transformation */
.sliderContainer {
  width: 100%;
  height: 50%;
  position: relative;

  @media (--viewportMedium) {
    max-width: 1300px;
  }
  @media (--viewportXLarge) {
    max-width: 1400px;
  }
}

.sliderCardsWrapper {
}

.slide {
  position: absolute;
  width: 1%;
  height: 1%;
  object-fit: cover;
  border-radius: 15px;
  transition: opacity 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84), transform 600ms ease;
  @media (min-width: 768px) {
    width: 100%;
  }
  /*   @media (--viewportMLarge) {
  }
  @media (--viewportMaxSmallDevice) {
  } */
}

.slide-0 {
  transform: translateX(-30%);
  opacity: 0.3;
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    transform: translateX(-40%);
  }
  @media (max-width: 768px) {
    transform: translateX(-65%);
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.slide-1 {
  transform: translateX(0%);
  opacity: 1;
  @media (max-width: 768px) {
    transform: translateX(0%);
  }
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    transform: translateX(0%);
  }
}

.slide-2 {
  transform: translateX(30%);
  @media (max-width: 768px) {
    transform: translateX(65%);
  }
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    transform: translateX(40%);
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.slide-3 {
  transform: translateX(60%);
  @media (max-width: 768px) {
    transform: translateX(130%);
  }
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    opacity: 0.3;
    transform: translateX(80%);
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.slide-4 {
  transform: translateX(90%);
  opacity: 0.3;
  @media (max-width: 768px) {
    transform: translateX(195%);
  }
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    transform: translateX(120%);
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.slide-5 {
  transform: translateX(120%);
  @media (max-width: 768px) {
    transform: translateX(260%);
  }
  @media only screen and (max-width: 1300px) and (min-width: 1024px) {
    transform: translateX(160%);
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
  opacity: 0.3;
}

.hidden {
  display: none;
}
.slider-buttons {
  pointer-events: none;
  position: absolute;
  display: flex;
  top: 80%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 7;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 600px) {
    width: 400px;
  }
}

.slider-button-left {
  pointer-events: all;
  position: absolute;
  display: flex;
  top: 65%;
  left: 4%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 99;
  width: 6%;
  justify-content: space-between;
}

.slider-button {
  pointer-events: all;
  padding: 0.5em 0.8em;
  background: white;
  border: 1px solid #eb7242;
  border-radius: 50%;
  margin: 1em;
  width: 45px;
  color: #eb7242;
  @media screen and (max-width: 600px) {
    margin-left: -0.5em;
  }
}

.slider-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.slider-button:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.backToSearchBtn {
  width: fit-content;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 50px;
  border-radius: 10px;
  background-color: #eb7242;
  z-index: 100;
  @media screen and (min-width: 1024px) {
    position: relative;
  }
}
.backToSearchBtn:hover {
  background-color: #ef9c7c;
}

/*Mobile Slider css*/
.mobileSlide {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  transition: opacity 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84), transform 600ms ease;
}
.mobileSlide-0 {
  transform: translateX(-115%);
  opacity: 0.3;
}
.mobileSlide-1 {
  transform: translateX(0%);
  opacity: 1;
}
.mobileSlide-2 {
  transform: translateX(125%);
}
.mobileSlide-3 {
  transform: translateX(230%);
}
.priceText {
  font-size: 14px;
}

.spinnerContainer {
  max-width: 93%;
  height: 320px;
  object-fit: cover;
  padding-top: 110px;
  padding-left: 25px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinnerDot {
  width: 10px;
  height: 10px;
  background-color: #1c7881;
  border-radius: 50%;
  margin: 0 5px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.discoverContainer {
  position: relative;
  z-index: 1;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 7em;

  @media screen and (min-width: 1023px) {
    margin-bottom: 0;
  }
}

.showSuggestionsButton {
  width: 300px !important;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #eb7242;

  @media screen and (min-width: 1900px) {
    width: 400px !important;
  }
}
.showSuggestionsButton:hover {
  background-color: #ef9c7c;
}
.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 254, 254, 0.3);
  border-radius: 50%;
  border-top-color: #045e66;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loadingContainer {
  margin-top: 35px;
  width: 60px;
  height: 60px;
  color: #000000;
  text-align: center;
}
.sectionTitleWrapper {
  padding-top: 15px;
  height: 100px;
  width: 100%;
  margin: 0;
}
.btnWrapper {
  height: 120px;
  width: 55vh;
  justify-items: auto;
  margin-top: -1px;
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: 900px) {
    display: none;
  }
}
