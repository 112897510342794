
@import '../../../../styles/customMediaQueries.css';

.outerWrapper {
  margin: 1em;
  @media (--viewportMedium) {
    margin: 1em 0 1em 0;
  }
}

.outerWrapper > h2 {
  font-family: Noto Sans JP;
  color: var(--successColor);
}

.firstRow {
  display: flex;
  flex-direction: column-reverse;
  @media (--viewportSmall) {
    display: flex;
    flex-direction: row;
    column-gap: 0;
    height: auto;
  }
}

.firstRowSwapped {
  flex-direction: column;
  @media (--viewportSmall) {
    display: flex;
    flex-direction: row;
    column-gap: 0;
    height: auto;
  }
}

.secondRow {
  flex-direction: column;
  margin-top: 0px;
  @media (--viewportSmall) {
    display: flex;
    flex-direction: row;
    margin-top: -10px;
  }
}

.leftSectionFirst {
  padding-right: 0px;
  margin: 1em auto;
  width: 100%;

  @media (--viewportSmall) {
    width: 50%;
    padding-right: 20px;
    margin: 0;
  }
}

.leftSectionSecond {
  padding-right: 0px;
  margin: 1em auto;
  display: flex;

  @media (--viewportSmall) {
    width: 50%;
    padding-right: 20px;
    margin: 0 0 50px 0;
  }
}

.rightSection {
  padding-left: 0px;
  display: flex;
  margin: 1em auto;
  font-family: var(--fontFamily);
  line-height: 1.35em;
  color: #4a4a4a;
  width: 100%;

  @media (--viewportSmall) {
    width: 50%;
    padding-left: 15px;
    margin: 0;
  }
}

.rightSectionSwapped {
  padding-left: 0px;
  width: 45% !important;
  @media (--viewportSmall) {
    width: 50%;
  }
}

.projectPhotoLeft {
  border-radius: 5px;
  width: 100%;
  height: 130px;
  object-fit: cover;

  @media (--viewportSmall) {
    width: 100%;
    height: 140px;
  }

  @media screen and (--viewportMedium) {
    height: 180px;
  }

  @media screen and (--viewportLarge) {
    height: 230px;
  }
}

/* White background */
.projectImageBackground {
  background-color: whitesmoke;
  transform: rotate(20deg);
  translate: 40px 0;
  width: 180px;
  height: 190px;
  margin: 0 auto;
  padding: 10px 10px 100px 10px;
  box-shadow: rgba(0, 0, 0, 0.5) 5px 12px 35px;
  transition: transform 1s ease-out;

  &:hover{
    transform: rotate(10deg);
  }
}

.projectImageBackground2 {
  background-color: #ffffff;
  transform: rotate(-2deg);
  translate: -80px 0;
  width: 180px;
  height: 190px;
  margin: 0 auto;
  padding: 10px 10px 100px 10px;
  box-shadow: rgba(0, 0, 0, 0.7) 5px 15px 25px;
  transition: transform 1s ease-out;

  &:hover{
    transform: rotate(-8deg);
  }
}

.projectImageBackground3 {
  background-color: whitesmoke;
  transform: rotate(-13deg);
  translate: 50px 0;
  width: 180px;
  height: 190px;
  margin: 0 auto;
  padding: 10px 10px 100px 10px;
  box-shadow: rgba(0, 0, 0, 0.6) 5px 10px 15px;
  z-index: 3;
  transition: transform 1s ease-out;

  &:hover{
    transform: rotate(-19deg);
  }
}

.projectImageBackground4 {
  background-color: white;
  transform: rotate(7deg);
  translate: -80px 0;
  width: 180px;
  height: 190px;
  margin: 0 auto;
  padding: 10px 10px 100px 10px;
  box-shadow: rgba(0, 0, 0, 0.5) 2px 15px 25px;
  transition: transform 1s ease-out;

  &:hover{
    transform: rotate(-2deg);
  }
}



@media screen and (--viewportSmall) {
  .projectImageBackground {
    translate: 60px 80px;
    width: 900px;
    height: 190px;
  }
  .projectImageBackground2 {
    translate: -70px 90px;
    width: 900px;
    height: 190px;
  }
  .projectImageBackground3 {
    translate: 60px 80px;
    width: 900px;
    height: 190px;
  }
  .projectImageBackground4 {
    translate: -130px 90px;
    width: 900px;
    height: 190px;
  }
}

@media screen and (--viewportMedium) {
  .projectImageBackground {
    translate: 60px 80px;
    width: 900px;
    height: 230px;
  }
  .projectImageBackground2 {
    translate: -70px 90px;
    width: 900px;
    height: 230px;
  }
  .projectImageBackground3 {
    translate: 60px 60px;
    width: 900px;
    height: 230px;
  }
  .projectImageBackground4 {
    translate: -130px 50px;
    width: 900px;
    height: 230px;
  }
}

@media screen and (--viewportLarge) {
  .projectImageBackground {
    translate: 60px -50px;
    width: 900px;
    height: 290px;
  }
  .projectImageBackground2 {
    translate: -130px -60px;
    width: 900px;
    height: 290px;
  }
  .projectImageBackground3 {
    translate: 60px 10px;
    width: 900px;
    height: 290px;
  }
  .projectImageBackground4 {
    translate: -130px 20px;
    width: 900px;
    height: 290px;
  }
}

@media screen and (--viewportMLarge) {
  .projectImageBackground {
    translate: 60px -50px;
    width: 900px;
    height: 320px;
  }
  .projectImageBackground2 {
    translate: -130px -60px;
    width: 900px;
    height: 320px;
  }
  .projectImageBackground3 {
    translate: 60px 20px;
    width: 900px;
    height: 320px;
  }
  .projectImageBackground4 {
    translate: -130px 30px;
    width: 900px;
    height: 320px;
  }
}

/* White background END */

.projectPhotoRight {
  border-radius: 5px;
  width: 100%;
  height: 130px;
  object-fit: cover;

  @media (--viewportSmall) {
    width: 100%;
    height: 140px;
  }

  @media screen and (--viewportMedium) {
    height: 180px;
  }

  @media screen and (--viewportLarge) {
    height: 230px;
  }
}

.paragraphsLeft {
  text-align:flex-start;
  font-family: var(--fontFamily);
  color: #4a4a4a;
  line-height: 1.35em;
  @media (--viewportSmall) {
    padding-top: 5px;
    width: 100%;
    object-fit: cover;
  }
}

.paragraphsRight {
  margin-top: 15px;
  width: 100%;
  text-align:flex-start;
  font-family: var(--fontFamily);
  color: #4a4a4a;
  line-height: 1.35em;
  @media (--viewportSmall) {
    width: 82%;
    object-fit: cover;
  }
}
