.notIncludedListContainer{
    column-count: 1;
    margin:1em 0;
 
 }
 .notIncluded{
     display: flex;
     align-items: flex-end;
     flex-direction: row-reverse;
     justify-content: flex-end;
     margin:1em 0;
   
 }
 .notIncluded span {
    text-decoration: line-through;
 }
 
 .notIncludedImg{
     width: 50px;
     margin-right: 1em;
     
 }
 .title{
    color: #353535;
 }
 .showMoreButton{
 
    display: flex;
    justify-content: center;
    width:100%;
    border: none;
    cursor: pointer;
  
    height: 30px;
    margin-top: 1em;
    
  }