
.foodListingContainer{
    display: flex;
    flex-direction: column;
    margin:1em 0;
}
.foodListing{
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 1em;
    flex: 0 0 50%; /* Distribute the width equally between two columns */
  
}

.foodListingImg{
    width: 50px;
    margin-right: 1em;
    
}
.title{
    color: #353535;
 }