

.sliderContainerWrapper {
  position: relative;
  max-width: 100%;
  height: 380px;
  overflow: hidden;
  @media (--viewportMedium) {
    max-width: 800px;
  }
  @media (--viewportLarge) {
    max-width: 820px;
  }
  @media (--viewportXLarge) {
    max-width: 875px;
  }
}

.sliderContainer {
  width: 100%;
  height: 380px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 0;
  overflow: hidden;

  @media (--viewportMedium) {
    width: calc(100% - 93px);
    margin-left: 47px;
  }
}

.sliderContainerAlignedLeft {
  width: 100%;

  @media screen and (max-width: 568px) {
    max-width: 800px;
  }
  @media (--viewportLarge) {
    margin-left: -30px;
  }
}

.sliderWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  transition: transform 0.5s ease;
  overflow: visible;
  margin: 0 0 -20px 5px;
  gap: 1.5em;
  @media screen and (max-width: 568px) {
    gap: 0.75em;
    margin: 0;
  }
}

.slide {
  flex: 0 0 calc(33.333% - 70px);
  max-width: 300px;
  margin: 0;
  transition: all 0.5s ease;
  cursor: pointer;
  overflow: visible;
}

.sliderButtons {
  position: absolute;
  top: 50%;
  left: 2px;
  right: 2px;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 1;
}

.sliderButton {
  background-color: white;
  border: 1px solid #eb7242;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.01s ease;
  width: 30px;
  height: 30px;
  pointer-events: all;
  z-index: 1;

  @media (--viewportMedium) {
    width: 40px;
    height: 40px;
  }
}

.sliderButton:hover {
  transform: scale(1.1);
}

.listingImage {
  width: 230px;
  height: 220px;
  border-radius: 3px;
  object-fit: cover;

  @media (--viewportMedium) {
    width: 270px;
  }
}

.listingImageSelected {
  width: 225px;
  height: 220px;
  border-radius: 3px;
  object-fit: cover;

  @media (--viewportMedium) {
    width: 265px;
  }
}

.listingCardContainer {
  background-color: white;
  padding: 5px;
  width: 240px;
  height: 360px;
  border-radius: 5px;
  box-shadow: var(--boxShadow);
  transition: transform 0.1s ease;
  z-index: 1;
  position: relative;

  @media (--viewportMedium) {
    width: 280px;
  }
}

.listingCardContainer:hover {
  transform: scale(1.02);
  border: 1px solid #1c7881;
  z-index: 10;
}

.listingCardContainerSelected {
  border: 3px solid #eb7242;
  background-color: white;
  padding: 5px;
  width: 240px;
  height: 360px;
  border-radius: 5px;
  box-shadow: var(--boxShadow);
  transition: transform 0.1s ease;
  z-index: 1;
  position: relative;

  &:hover {
    transform: scale(1.01);
    z-index: 10;
  }

  @media (--viewportMedium) {
    width: 280px;
  }
}

.reviewRatings {
  height: 90px;
  width: 20%;
  display: flex;
  align-items:flex-start;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: -25px;
  @media (--viewportMedium) {
    height: 80px;
  }
}

.listingContent {
  display: flex;
  height: 110px;
  flex-direction: column;
  align-items:flex-start;
  justify-content: space-between;
  width: 80%;
  gap: 10px;
  margin-top: 5px;
  border-left: 1px solid black;
  padding-left: 10px;
  overflow: hidden;
  color: #333;
}

.listingInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:flex-start;
  margin-top: 5px;
}

.listingTitle {
  display: flex;
  color: #1c7881;
  font-size: 1em;
  font-weight: 500;
}

.listingDescription {
  font-size: 0.8em;
}

.listingLogo {
  display: flex;
  width: 22px;
}

.listingDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.invisible {
  display: none;
  @media screen and (max-width: 568px) {
    position: relative;
    top: 55%;
    width: 106%;
    left: -3%;
    right: 2px;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    /*     pointer-events: none; */
    z-index: 1;
  }
}

.sliderWrapperThreeCards {
  display: flex;
  height: 100%;
  align-items: center;
  transition: transform 0.5s ease;
  overflow: visible;
  margin: 0 0 -20px 5px;
  gap: 1.5em;
}

@media (max-width: 450px) {
  .sliderContainer {
    width: 100%;
    height: 380px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
  }

  .sliderContainerThreeCards {
    width: calc(100% - 40px) !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .sliderWrapperThreeCards {
    margin: 0;
    gap: 0.8em;
  }

  .slideThreeCards {
    flex: 0 0 160px !important;
    max-width: 160px !important;
  }
}

.topRightBadge {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.badgeIconExp {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.badgeIconStay {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.badgeText {
  font-size: 0.8em;
  color: #333;
  font-weight: lighter;
}
