
@import '../../../../styles/customMediaQueries.css';

.reviewsContainer {
  display: flex;
  flex-direction: column;
  padding: 1em;
  margin-bottom: 1em;
  background-color: #ffffff;
  border-radius: 5px;
  position: relative;
  z-index: -1;
}
.reviewsContainerLeft {
  display: flex;
  align-items: center;

  margin-right: 0.5em;
}
.reviewsContainerLeftText {
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: bold;
  margin: 4px 0 0 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  flex-direction: row;
}
.date {
  font-size: 0.9rem;
  margin: 0 0 0 1.25em;
}
.avatarImg {
  width: 50px;
  height: 50px;

  object-fit: contain;
  object-position: center;
  border-radius: 50%;
  border: 1px solid var(--successColor);
  overflow: hidden;
}
.avatarImg svg {
  width: 50%;
}
.avatarImg .noImageText {
  width: 50%;
}
.btnTextContainer {
  display: flex;
}

.openBtn {
  margin: 2em 1em 1em 0;
  background-color: white;
  height: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1em;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  border: 1px solid #eb7242;
  color: #eb7242;
  cursor: pointer;
}
.openBtn2 {
  @media screen and (min-width: 1000px) {
    margin: -7em 1em 1em 0;
  }
}
.btnText {
  cursor: pointer;
}
.showMoreLessText {
  display: flex;
  align-items: center;
}
.showMoreLessText > span {
  text-decoration: underline;
}
.Gt {
  text-decoration: none !important;
  margin-left: 0.5em;
}

.noReviewsContainer {
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (--viewportLarge) {
    margin: 1em 0;
    width: 55vw;
    align-items: flex-start;
  }
}
.noReviewsBtn {
  margin: 2em 1em 1em 0;
  background-color: white;
  height: 50px;

  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1em;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  color: white;
  background-color: #eb7242;
  cursor: pointer;
}
/*for showing only 4 lines*/

.showLines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Limit to 4 lines */
  -webkit-box-orient: vertical;
  line-height: 30px;
  margin-bottom: 0;
}

/* Add this CSS class to show the full content when expanded */
.expandedContent {
  /* Remove the line-clamp to show all lines */
  -webkit-line-clamp: unset;
  line-height: 30px;
}

@media (min-width: 1024px) {
  .reviewsGeneralContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
  }
  .reviewsContainer {
    margin-bottom: 0;
    flex-direction: row;
    align-items: flex-start;
    z-index: 0;
  }
  .reviewsContainerLeft {
    flex-direction: column;
    align-items: flex-start;
    margin: 16px 1em 0.5em 1em;
    width: 8vw;
  }

  .reviewsContainerLeftText {
    display: flex;
    flex-direction: column;
    width: 8vw;
  }
  .reviewsContainerLeftText p {
    margin: 0;
  }
}

.singleReviewRating {
  width: 100%;
  display: flex;
}

.googleIcon {
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
}

.reviewsWrapper {
  display: flex;
  justify-content:flex-start;
}
